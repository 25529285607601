import { Privilege } from '@spec/Grants';
import { StrictMap } from '../lib/MapUtils';

export const privilegeLabelMap: StrictMap<Privilege, string> = new StrictMap([
    ['EDIT_TALENT', '従業員情報の編集'],
    ['READ_LOGS', '操作ログ閲覧'],
    ['SHOW_LEAVED_TALENT', '退職者閲覧'],
    ['SHOW_FULL_PERSONALITY', 'プライバシー情報閲覧'],
    ['GRANT_PRIVILEGE', '権限の付与'],
    ['EDIT_WORKPLACES', '勤務地の編集'],
    ['MANAGE_CIRCLE', 'サークル管理'],
    ['MANAGE_VALUE_FEEDBACK', 'バリューフィードバック管理'],
    ['UPLOAD_PROFILE_IMAGE', 'プロフィール画像設定'],
    ['MANAGE_TODO', 'やること管理'],
    ['DOWNLOAD_CSV', 'CSVダウンロード'],
    ['MANAGE_SURVEY', 'サーベイ管理'],
    ['MANAGE_DIVE', 'DIVE管理'],
    ['SHOW_HR_DASHBOARD', 'HRダッシュボード閲覧'],
    ['EDIT_TALENT_TAGS', '人のタグ編集'],
    ['MANAGE_HITOUCH', 'ハイタッチ管理'],
]);
