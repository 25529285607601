import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import type { SurveyBooleanQuestion } from '@spec/Survey';
import { useApplyFormContext } from './Context';

export const BooleanQuestionForm: React.FC<{ question: SurveyBooleanQuestion }> = (props) => {
    const { responses, setResponses } = useApplyFormContext();
    return (
        <Box ml={2}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={!!responses.get(props.question.id)}
                        onChange={(e) => {
                            setResponses(props.question.id, e.target.checked);
                        }}
                        name="checked"
                        size="small"
                    />
                }
                label={<Typography variant="body2">{props.question.label}</Typography>}
            />
        </Box>
    );
};
