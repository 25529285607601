import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import type { SurveySelectQuestion } from '@spec/Survey';
import { useApplyFormContext } from './Context';

export const SelectQuestionForm: React.FC<{ question: SurveySelectQuestion }> = (props) => {
    const { responses, setResponses } = useApplyFormContext();
    const q = props.question;
    return (
        <RadioGroup
            sx={{ mx: 2, my: 1 }}
            value={responses.get(props.question.id ?? '')}
            onChange={(e) => {
                setResponses(q.id, [e.target.value]);
            }}
        >
            {q.options.map((option) => (
                <FormControlLabel
                    key={option}
                    label={<Typography>{option}</Typography>}
                    value={option}
                    control={<Radio />}
                />
            ))}
        </RadioGroup>
    );
};
