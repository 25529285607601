import { Box, TextField } from '@mui/material';
import type { SurveyTextQuestion } from '@spec/Survey';
import { useApplyFormContext } from './Context';

export const TextQuestionForm: React.FC<{ question: SurveyTextQuestion }> = (props) => {
    const { responses, setResponses } = useApplyFormContext();
    return (
        <Box>
            <TextField
                fullWidth
                multiline
                minRows={4}
                value={responses.get(props.question.id ?? '')}
                onChange={(e) => setResponses(props.question.id, e.target.value)}
            />
        </Box>
    );
};
