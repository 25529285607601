import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import type { SurveyLikertQuestion } from '@spec/Survey';
import { useState } from 'react';
import { useMobile } from '../../../Theme';
import { FlexBox } from '../../FlexBox';
import { useApplyFormContext } from './Context';

export const LikertQuestionForm: React.FC<{ question: SurveyLikertQuestion }> = (props) => {
    const q = props.question;
    const scores = [];
    for (let i = q.minScore; i <= q.maxScore; i++) {
        scores.push(i);
    }
    const [score, setScore] = useState<number | null>(null);
    const { setResponses } = useApplyFormContext();
    return useMobile() ? (
        <Box mx={2} my={1}>
            <FlexBox>
                <Typography flexGrow={1} variant="body2">
                    {q.minLabel}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'right' }}>
                    {q.maxLabel}
                </Typography>
            </FlexBox>
            <RadioGroup
                row
                sx={{
                    marginTop: 1,
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap',
                }}
                value={score}
                onChange={(e) => {
                    const v = Number(e.target.value);
                    setScore(v);
                    setResponses(q.id, v);
                }}
            >
                {scores.map((score) => (
                    <FormControlLabel
                        key={score}
                        label={<Typography variant="caption">{score}</Typography>}
                        value={score}
                        control={
                            <Radio
                                size="small"
                                disableFocusRipple
                                disableRipple
                                sx={{ padding: 0 }}
                            />
                        }
                        labelPlacement="top"
                    />
                ))}
            </RadioGroup>
        </Box>
    ) : (
        <Box my={1}>
            <FlexBox justifyContent="center" alignItems="flex-end" flexWrap="nowrap" gap={2}>
                <Typography variant="body2" flexBasis={0} flexGrow={1} textAlign="right">
                    {q.minLabel}
                </Typography>
                <RadioGroup
                    row
                    sx={{
                        width: '300px',
                        justifyContent: 'space-between',
                        flexWrap: 'nowrap',
                    }}
                    value={score}
                    onChange={(e) => {
                        const v = Number(e.target.value);
                        setScore(v);
                        setResponses(q.id, v);
                    }}
                >
                    {scores.map((score) => (
                        <FormControlLabel
                            key={score}
                            label={<Typography variant="caption">{score}</Typography>}
                            value={score}
                            control={
                                <Radio
                                    size="small"
                                    disableFocusRipple
                                    disableRipple
                                    sx={{ padding: 0 }}
                                />
                            }
                            labelPlacement="top"
                        />
                    ))}
                </RadioGroup>
                <Typography variant="body2" flexBasis={0} flexGrow={1}>
                    {q.maxLabel}
                </Typography>
            </FlexBox>
        </Box>
    );
};
