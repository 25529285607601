import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Container,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import {
    Survey,
    SurveyApplyingItem,
    SurveyGroup,
    SurveyPeriod,
    SurveyQuestion,
} from '@spec/Survey';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApplicationError } from '../../../Errors';
import { FormTheme } from '../../../Theme';
import {
    findActivePeriod,
    isBooleanQuestion,
    isLikertQuestion,
    isSelectQuestion,
    isTextQuestion,
} from '../../../domains/Survey';
import { findById } from '../../../lib/ArrayUtils';
import { useEmptyForm } from '../../../lib/Form';
import { useApplySurvey, useIsAppliedSurveyGroup } from '../../../queries/survey';
import { ActionContainer } from '../../ActionButtons';
import { useCurrentTimeContext } from '../../Context';
import { Markdown } from '../../Markdown';
import { NoItems } from '../../NoItems';
import { SubTitle } from '../../PageTitle';
import { DialogActionButtons, StableDialog } from '../../StableDialog';
import { TenantContent } from '../../TenantContent';
import { WaitLoading } from '../../WaitLoading';
import { useSurveysContext } from '../Context';
import { BooleanQuestionForm } from './BooleanQuestionForm';
import { ContextProvider, ResponseMap, ResponseValue, useApplyFormContext } from './Context';
import { LikertQuestionForm } from './LikertQuestionForm';
import { SelectQuestionForm } from './SelectQuestionForm';
import { TextQuestionForm } from './TextQuestionForm';

export const ApplySurvey: React.FC = () => {
    const { groupId } = useParams();
    const { surveyGroups } = useSurveysContext();
    const surveyGroupId = Number(groupId);
    const currentGroup = findById(surveyGroupId, surveyGroups);
    const isApplied = useIsAppliedSurveyGroup(surveyGroupId);
    const { currentTime } = useCurrentTimeContext();
    const period = findActivePeriod(currentGroup, dayjs(currentTime));
    if (period === null) {
        return <NoItems mt={8}>現在「{currentGroup.name}」は実施されていません</NoItems>;
    }
    return (
        <Box>
            <ContextProvider>
                <Typography mb={1} textAlign="center" variant="h6">
                    {currentGroup.name} {period.name}
                </Typography>
                <Container maxWidth="md">
                    <Card>
                        <CardContent>
                            <Markdown source={currentGroup.description} variant="body2" />
                        </CardContent>
                    </Card>
                </Container>
                <WaitLoading size="medium" waitFor={[isApplied]}>
                    {isApplied.data === true && (
                        <Box m={4}>
                            <Typography textAlign="center" color="primary">
                                回答済みのサーベイです
                            </Typography>
                        </Box>
                    )}
                    {isApplied.data === false && <ApplyForm group={currentGroup} period={period} />}
                </WaitLoading>
            </ContextProvider>
        </Box>
    );
};

const toItems = (x: ResponseMap): SurveyApplyingItem[] => {
    const items: SurveyApplyingItem[] = [];
    const toItem = (questionId: number, value: ResponseValue): SurveyApplyingItem => {
        switch (typeof value) {
            case 'number':
                return { questionId, score: value };
            case 'boolean':
                return { questionId, checked: value };
            case 'string':
                return { questionId, message: value };
            default:
                if (Array.isArray(value)) {
                    return { questionId, options: value };
                }
                throw new ApplicationError(
                    `unexpected response for question ${questionId}: ${value}`
                );
        }
    };
    for (const [questionId, response] of x.entries()) {
        items.push(toItem(questionId, response));
    }
    return items;
};

const ApplyForm: React.FC<{ group: SurveyGroup; period: SurveyPeriod }> = (props) => {
    const form = useEmptyForm();
    const { responses } = useApplyFormContext();
    const { surveys } = useSurveysContext();
    const [openDialog, setOpenDialog] = useState(false);
    const closeDialog = useCallback(() => {
        setOpenDialog(false);
    }, []);
    const mutation = useApplySurvey(props.group.id);
    return (
        <FormTheme>
            {surveys
                .filter((v) => v.surveyGroupId === props.group.id)
                .map((v) => (
                    <SurveyApplyForm key={v.id} period={props.period} survey={v} />
                ))}
            <ActionContainer>
                <Button
                    variant="contained"
                    size="medium"
                    fullWidth
                    disabled={responses.size === 0 || form.succeeded === true}
                    onClick={() => setOpenDialog(true)}
                >
                    サーベイに回答する
                </Button>
            </ActionContainer>
            {form.succeeded === true && (
                <Box mt={2}>
                    <Container maxWidth="md">
                        <Alert severity="success">サーベイに回答しました</Alert>
                    </Container>
                </Box>
            )}
            <StableDialog open={openDialog} noClose onClose={closeDialog} maxWidth="sm" fullWidth>
                <DialogTitle>{props.group.name}に回答する</DialogTitle>
                <DialogContent>
                    <Typography>回答結果は上書きできません。今の内容で確定しますか？</Typography>
                    <TenantContent
                        carta={
                            <Alert severity="warning" sx={{ mt: 2 }}>
                                「CARTAパルスサーベイ」の設問は事業部の上長も閲覧可能です
                            </Alert>
                        }
                    />
                </DialogContent>
                <DialogActionButtons
                    form={form}
                    submitLabel="今の内容で回答を確定する"
                    cancelLabel="入力画面に戻る"
                    errorMessage="回答に失敗しました"
                    onSubmit={() => mutation.mutateAsync({ responses: toItems(responses) })}
                    closeDialog={closeDialog}
                />
            </StableDialog>
        </FormTheme>
    );
};

const SurveyApplyForm: React.FC<{ period: SurveyPeriod; survey: Survey }> = (props) => {
    const surveyQuestionIds = props.survey.questions.map((v) => v.id);
    const questions = props.period.questions.filter((v) => surveyQuestionIds.includes(v.id));
    return (
        <Box mt={2}>
            <Container maxWidth="md">
                <SubTitle title={props.survey.name} />
                <Markdown source={props.survey.description} variant="body2" />
                {questions.map((q) => (
                    <QuestionForm key={q.id} question={q} />
                ))}
            </Container>
        </Box>
    );
};

const QuestionForm: React.FC<{ question: SurveyQuestion }> = (props) => {
    const q = props.question;
    return (
        <Box mt={2}>
            <Card>
                <CardContent>
                    <Typography>{q.title}</Typography>
                    {isLikertQuestion(q) && <LikertQuestionForm question={q} />}
                    {isBooleanQuestion(q) && <BooleanQuestionForm question={q} />}
                    {isTextQuestion(q) && <TextQuestionForm question={q} />}
                    {isSelectQuestion(q) && <SelectQuestionForm question={q} />}
                </CardContent>
            </Card>
        </Box>
    );
};
