import {
    Box,
    Checkbox,
    FormControlLabel,
    List,
    ListItem,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { SurveyGroup } from '@spec/Survey';
import { findChildTeams, findParentTeams } from '../../../../domains/Organization';
import { getReadableTeams } from '../../../../domains/Survey';
import { sortByKey } from '../../../../lib/ArrayUtils';
import { useMeContext } from '../../../../queries/me';
import { useTalentsContext, useTeamsContext } from '../../../Context';
import { FilterCondition, FilterRow, FilterForm as Form } from '../../../FilterForm';
import { FlexBox } from '../../../FlexBox';
import { HelpTip } from '../../../HelpTip';
import { MultiSelect } from '../../../MultiSelect';
import { TeamDrill } from '../../../TeamDrill';
import { isHiringCategory } from '../../../talent/list/filter';
import { useSurveysContext } from '../../Context';
import { useFilterFormContext } from './Context';

const FIRST_LABEL_WIDTH = 2.5;

export const FilterForm: React.FC<{ surveyGroup: SurveyGroup }> = (props) => {
    const { condition, updateCondition } = useFilterFormContext();
    const { teams } = useTeamsContext();
    const { surveys } = useSurveysContext();
    const { grants } = useMeContext();
    const selectableTeamIds = new Set(
        surveys
            .filter((v) => v.surveyGroupId === props.surveyGroup.id)
            .map((v) =>
                getReadableTeams(v, teams, grants.survey)
                    .map((v) => [...findChildTeams(v.id, teams), ...findParentTeams(v.id, teams)])
                    .flat()
                    .map((v) => v.id)
            )
            .flat()
    );
    const periods = sortByKey(props.surveyGroup.periods, 'openedAt', 'desc');

    const { talents } = useTalentsContext();
    const years = new Set(talents.map((v) => v.joinedAt.getFullYear()));

    return (
        <Box>
            <Box mb={2}>
                <Typography>期間を選択</Typography>
                <TextField
                    select
                    value={condition.periodId}
                    onChange={(e) => updateCondition({ periodId: Number(e.target.value) })}
                >
                    {periods.map((v) => (
                        <MenuItem key={v.id} value={v.id}>
                            {v.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <Form dense>
                <FilterRow>
                    <FilterCondition label="入社年" labelWidth={FIRST_LABEL_WIDTH}>
                        <MultiSelect
                            values={condition.joinedYears}
                            setItems={(v) => updateCondition({ joinedYears: v.map(Number) })}
                        >
                            {[...years]
                                .sort()
                                .reverse()
                                .map((v) => (
                                    <MenuItem key={v} value={v}>
                                        {v}
                                    </MenuItem>
                                ))}
                        </MultiSelect>
                    </FilterCondition>
                    <FilterCondition label="入社区分">
                        <TextField
                            select
                            value={condition.hiringCategory}
                            onChange={(v) => {
                                const value = v.target.value;
                                if (isHiringCategory(value)) {
                                    updateCondition({ hiringCategory: value });
                                }
                            }}
                        >
                            <MenuItem value="all">すべて</MenuItem>
                            <MenuItem value="newGraduate">新卒</MenuItem>
                            <MenuItem value="experienced">中途</MenuItem>
                        </TextField>
                    </FilterCondition>
                    <FilterCondition
                        flexGrow={1}
                        label={
                            <FlexBox>
                                キーワード
                                <HelpTip
                                    title={
                                        <Box m={1}>
                                            以下の項目にマッチします。
                                            <List dense disablePadding>
                                                <ListItem>ヒトノワID</ListItem>
                                                <ListItem>
                                                    氏名（漢字、よみがな、英語表記）
                                                </ListItem>
                                                <ListItem>ニックネーム</ListItem>
                                                <ListItem>Slack名</ListItem>
                                                <ListItem>
                                                    メールアドレス（エイリアス含む）
                                                </ListItem>
                                                <ListItem>GitHubID</ListItem>
                                                <ListItem>出向先の企業名</ListItem>
                                                <ListItem>役職</ListItem>
                                            </List>
                                        </Box>
                                    }
                                />
                            </FlexBox>
                        }
                    >
                        <Box flexGrow={1}>
                            <TextField
                                fullWidth
                                placeholder="名前やメールアドレス、出向先の企業名などで部分検索できます。"
                                value={condition.keyword}
                                onChange={(e) => updateCondition({ keyword: e.target.value })}
                            />
                        </Box>
                    </FilterCondition>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={condition.notApplied}
                                onChange={(e) => updateCondition({ notApplied: e.target.checked })}
                                size="small"
                            />
                        }
                        label={<Typography variant="body2">回答していない人も表示する</Typography>}
                    />
                </FilterRow>
                <FilterRow>
                    <FilterCondition label="所属" labelWidth={FIRST_LABEL_WIDTH}>
                        <TeamDrill
                            teamId={condition.teamId}
                            setTeamId={(v) => updateCondition({ teamId: v })}
                            filterTeam={(v) => selectableTeamIds.has(v.id)}
                        />
                    </FilterCondition>
                </FilterRow>
            </Form>
        </Box>
    );
};
